import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {fade} from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        common: {
            white: '#F9F4CE'
        },
        primary: {
            // main: '#0038b8'
            main: '#ed8c00',
            // light: '#64d8ff',
            // dark: '#0077a2'
        },
        secondary: {
            main: '#1E6246',
            // light: '#fff26c',
            // dark: '#ab9000'
        },
        error: {
            main: '#D05651'
        },
        warning: {
            main: '#D05651'
        },
        info: {
            main: '#558b2f'
        },
        background: {
            default: fade('#000', .15)
        },
    },
    overrides: {
        MuiTabs: {
            scrollButtons: {
                color: "#C88112"
            }
        }
    }
});

export default theme;