import {Effect} from "../models/Effect";
import {PointRange} from "../models/PointRange";
import {FoodMaterial} from "../models/FoodMaterial";
import {Grid, Typography} from "@material-ui/core";
import rupee from "../assets/rupee.png";
import theme from "../styles/theme";
import Loading from "../components/Loading";
import React from "react";
import {FoodPrep} from "../models/FoodPrep";

export const foodEffects: Effect[] = [
    Effect.ATTACK,
    Effect.CHILLY,
    Effect.DEFENSE,
    Effect.ELECTRIC,
    Effect.ENDURANCE,
    Effect.HEARTY,
    Effect.RANDOM,
    Effect.SPEED,
    Effect.SPICY,
    Effect.STAMINA,
    Effect.STEALTH,
];

export const elixerEffects: Effect[] = [
    Effect.ELIXER_ATTACK,
    Effect.ELIXER_CHILLY,
    Effect.ELIXER_DEFENSE,
    Effect.ELIXER_ELECTRIC,
    Effect.ELIXER_FIREPROOF,
    Effect.ELIXER_SPEED,
    Effect.ELIXER_SPICY,
    Effect.ELIXER_STAMINA,
    Effect.ELIXER_ENDURANCE,
    Effect.ELIXER_STEALTH,
    Effect.ELIXER_HEARTY,
];

export const timedEffects: Effect[] = [
    Effect.ATTACK,
    Effect.CHILLY,
    Effect.DEFENSE,
    Effect.ELECTRIC,
    Effect.SPEED,
    Effect.SPICY,
    Effect.STEALTH,
    Effect.ELIXER_ATTACK,
    Effect.ELIXER_CHILLY,
    Effect.ELIXER_DEFENSE,
    Effect.ELIXER_ELECTRIC,
    Effect.ELIXER_FIREPROOF,
    Effect.ELIXER_SPEED,
    Effect.ELIXER_SPICY,
    Effect.ELIXER_STEALTH,
];

export const calculateDisplayEffectDiff = (currentEffect: Effect, combinedEffect: Effect, incomingEffect: Effect): {effect: Effect, isEffectCanceling: boolean} => {
    // console.log(`current ${currentEffect}, combined ${combinedEffect}, incoming ${incomingEffect}`);
    if (currentEffect === Effect.NONE) return {effect: incomingEffect, isEffectCanceling: false};

    const effects = foodEffects.concat(elixerEffects);

    if (currentEffect === Effect.ROCK_HARD) return {effect: currentEffect, isEffectCanceling: false};

    if (incomingEffect === Effect.ELIXER) return {effect: Effect.ELIXER, isEffectCanceling: false};

    if (effects.includes(currentEffect)) {
        if (combinedEffect === Effect.DUBIOUS || combinedEffect === Effect.ROCK_HARD) return { effect: combinedEffect, isEffectCanceling: true };

        if (combinedEffect === Effect.NONE) return { effect: currentEffect, isEffectCanceling: true};

        if (currentEffect === combinedEffect) return { effect: currentEffect, isEffectCanceling: false };

        if (incomingEffect === Effect.TIME) {
            if (timedEffects.includes(currentEffect)) { return {effect: currentEffect, isEffectCanceling: false}; }
            else return { effect: Effect.TIME, isEffectCanceling: true };
        }
    } else {
        if (effects.includes(combinedEffect)) return { effect: incomingEffect, isEffectCanceling: false }
    }

    return {effect: Effect.NONE, isEffectCanceling: false}
}

export const isElixerDubious = (effect: Effect): boolean => {
    if (effect === Effect.TIME || effect.indexOf("ELIXER" ) > -1 || effect === Effect.ROCK_HARD) return false;
    return true
}

export const foodHasElixer = (effects: Effect[]): boolean => {
    return effects.filter( (effect) => effect === Effect.ELIXER).length > 0;
}

export const foodHasIngredients = (effects: Effect[]): boolean => {
    return effects.filter( (e) => {
        switch(e) {
            case Effect.ATTACK:
            case Effect.CHILLY:
            case Effect.DEFENSE:
            case Effect.ELECTRIC:
            case Effect.ENDURANCE:
            case Effect.HEART:
            case Effect.HEARTY:
            case Effect.RANDOM:
            case Effect.SPEED:
            case Effect.SPICY:
            case Effect.STAMINA:
            case Effect.STEALTH:
            case Effect.NONE:
                return true;
        }
    }).length > 0;
}

export const calculateMealMultiplier = (foodMaterials: FoodMaterial[]): number => {
    switch (foodMaterials.length) {
        case 2:
            return 1.75;
        case 3:
            return 2.05;
        case 4:
            return 2.4;
        case 5:
            return 2.8;
        default:
            return 1.5;
    }
}

export const calculateTotalMealValue = (foodMaterials: FoodMaterial[]): number => {
    const effects = foodMaterials.map( material => {
        return material.effectName
    });

    if (createsDubiousFood(effects) || createsHardFood(effects) ) return 0;

    let rupees = 0;

    foodMaterials.map( (foodMaterial: FoodMaterial) => {
        // console.log(foodMaterial.rupees);
        rupees += foodMaterial.rupees;
    });

    const value = Math.round(( rupees * calculateMealMultiplier(foodMaterials) ) / 10 ) * 10;

    return value < 10 ? 10 : value;
}

export const calculateCookedMaterialValue = (foodMaterials: FoodMaterial[], thisMaterial: FoodMaterial): number => {
    const effects = foodMaterials.map( material => {
        return material.effectName
    });

    if (createsDubiousFood(effects) || createsHardFood(effects) ) return 0;
    if (createsDubiousFood([thisMaterial.effectName]) || createsHardFood([thisMaterial.effectName]) ) return 0;

    if (foodMaterials.length > 5) return 0;
    const value = Math.round((
        calculateMealMultiplier(foodMaterials) * thisMaterial.rupees
    ) / 10 ) * 10;

    const totalValue = calculateTotalMealValue(foodMaterials);

    if (foodMaterials.length > 1 && (totalValue === value)) return 0;

    if (value < 10) return 10;

    return value;

}

export const isElixerEffect = (effects: Effect[]): boolean => {
    return effects.filter( (effect) => {
        return elixerEffects.includes(effect);
    }).length > 0;
}

export const isFoodEffect = (effects: Effect[]): boolean => {
    return effects.filter( (effect: Effect) => {
        return foodEffects.includes(effect);
    }).length > 0;
}

export const createsDubiousFood = (effects: Effect[]): boolean => {
    if ((foodHasElixer(effects) || isElixerEffect(effects)) && foodHasIngredients(effects)) return true;
    return false;
}

export const createsIncompleteElixer = (effects: Effect[]): boolean => {
    const hasElixer = foodHasElixer(effects);
    const hasElixerEffect = isElixerEffect(effects);

    if (hasElixerEffect && !hasElixer) return true;
    return false;
}

export const createsIncompleteTime = (effects: Effect[]): boolean => {
    if (calculateCookingEffect(effects) === Effect.TIME) return true;
    return false;
}

export const createsHardFood = (effects: Effect[]): boolean => {
    const hasHardFood = effects.join("").indexOf(Effect.ROCK_HARD) > -1;
    if (!createsDubiousFood(effects) && hasHardFood) return true;
    return false;
}


export const calculateCookingEffectFromMaterials = (foodMaterials: FoodMaterial[]): Effect => {
    const effects = foodMaterials.map( (fm) => fm.effectName);
    return calculateCookingEffect(effects);
}


export const createsOneEffect = (effects: Effect[]): Effect | false => {
    let effectCount = 0;
    let currentEffect = Effect.NONE;

    for (let effect of  foodEffects.concat(elixerEffects)) {
        if (effects.includes(effect)) {
            effectCount++;
            currentEffect = effect;
        }
        if (effectCount > 1) return false;
    }
    if (effectCount === 1) return currentEffect;
    return false;
}

export const justHearts = (effects: Effect[]): boolean => {
    const effectsLength = effects.length;
    if (effects.filter( (effect) => effect === Effect.HEART).length === effectsLength) return true;
    return false;
}

export const justElixers = (effects: Effect[]): boolean => {
    const effectsLength = effects.length;
    if (effects.filter( (effect) => effect === Effect.ELIXER).length === effectsLength) return true;
    return false;
}



export const calculateCookingEffect = (effects: Effect[]): Effect => {
    if (effects.length === 0) return Effect.NONE;

    if (createsDubiousFood(effects)) return Effect.DUBIOUS;
    if (createsHardFood(effects)) return Effect.ROCK_HARD;
    const singleEffect = createsOneEffect(effects);
    if (singleEffect) return singleEffect;
    if (justHearts(effects)) return Effect.HEART;
    if (justElixers(effects)) return Effect.ELIXER;
    return Effect.NONE;
}

export const calculatePointsFromMaterialsAndEffect = (materials: FoodMaterial[], effect: Effect): number => {
    let points = 0;
    if (elixerEffects.includes(effect)) {
        materials.map( (material) => {
            if (elixerEffects.includes(material.effectName)) {
                points += material.effectPoints;
            }
        });
    }
    else if (foodEffects.includes(effect)) {
        materials.map( (material) => {
            if (foodEffects.includes(material.effectName)) {
                points += material.effectPoints;
            }
        })
    }
    return points;
}

export const calculateEffectTimeFromMaterials = (materials: FoodMaterial[], effect: Effect): number => {
    let seconds = 0;
    if (timedEffects.includes(effect)) {
        let duplicateTimeMaterials: FoodMaterial[] = [];

        materials.forEach( (material: FoodMaterial) => {
            seconds += 30;
            if (duplicateTimeMaterials.includes(material)) return;
            if (material.effectName === Effect.TIME) duplicateTimeMaterials.push(material);
            seconds += material.effectTime;
        });

        if (seconds > 1800) return 1800

        return seconds;
    }
    return seconds;
}

export const calculateCookedHeartsFromMaterialsAndEffect = (materials: FoodMaterial[], effect: Effect): number => {
    let hearts = 0;
    materials.map( (material) => {
        hearts += material.cookedHearts;
    });
    hearts = (hearts > -1) ? hearts : 0;
    return hearts > 18 ? 18 : hearts;
}

export const calculateThresholdFromEffectandPoints = (effect: Effect, points: number): number => {
    const broadEffect = effect.replace("ELIXER_", "");
    switch (broadEffect) {
        case Effect.ATTACK:
        case Effect.DEFENSE:
        case Effect.SPEED:
            if (points >= 7) return 3;
            if (points >= 5) return 2;
            return points > 0 ? 1 : 0;

        case Effect.CHILLY:
        case Effect.SPICY:
            if (points >= 6) return 2;
            return points > 0 ? 1 : 0;
        case "FIREPROOF":
            if (points >= 7) return 2;
            return points > 0 ? 1 : 0;
        case Effect.ELECTRIC:
            if (points >= 6) return 3;
            if (points >= 4) return 2;
            return points > 0 ? 1 : 0;
        case Effect.STEALTH:
            if (points >= 9) return 3;
            if (points >= 6) return 2;
            return points > 0 ? 1 : 0;
        default:
            return points;
    }

    return points;

}

export const maxEffectPoints = (effect: Effect) => {
    switch (effect) {
        case Effect.ATTACK:
        case Effect.ELIXER_ATTACK:
        case Effect.DEFENSE:
        case Effect.ELIXER_DEFENSE:
        case Effect.SPEED:
        case Effect.ELIXER_SPEED:
        case Effect.ELECTRIC:
        case Effect.ELIXER_ELECTRIC:
        case Effect.STEALTH:
        case Effect.ELIXER_STEALTH:
            return 3;
        case Effect.SPICY:
        case Effect.ELIXER_SPICY:
        case Effect.CHILLY:
        case Effect.ELIXER_CHILLY:
        case Effect.ELIXER_FIREPROOF:
            return 2;
        default:
            return 3;
    }

}

export const getPointRangeFromEffect = (effect: Effect): PointRange => {
    switch(effect) {

        case Effect.ATTACK:
        case Effect.DEFENSE:
            return {
                low: 7,
                mid: 14,
                high: 21
            }
        case Effect.CHILLY:
        case Effect.SPICY:
            return {
                low: 5,
                mid: 10,
                high: null
            }
        case Effect.ELECTRIC:
            return {
                low: 8,
                mid: 16,
                high: null
            }
        case Effect.RANDOM:
            return {
                low: 60,
                mid: 600,
                high: 1800
            }
        case Effect.SPEED:
            return {
                low: 7,
                mid: 14,
                high: null
            }
        case Effect.ELIXER_ENDURANCE:
        case Effect.ENDURANCE:
            return {
                low: 4,
                mid: 7,
                high: 11
            }
        case Effect.ELIXER_STAMINA:
        case Effect.STAMINA:
            return {
                low: 10,
                mid: null,
                high: null
            }
        case Effect.STEALTH:
            return {
                low: 5,
                mid: 10,
                high: 15
            }
        case Effect.ELIXER:
            return {
                low: 40,
                mid: 80,
                high: 160
            }
        case Effect.ELIXER_SPICY:
        case Effect.ELIXER_CHILLY:
        case Effect.ELIXER_FIREPROOF:
            return {
                low: 29,
                mid: null,
                high: null
            }
        case Effect.ELIXER_ELECTRIC:
        case Effect.ELIXER_SPEED:
        case Effect.ELIXER_STEALTH:
        case Effect.ELIXER_ATTACK:
        case Effect.ELIXER_DEFENSE:
            return {
                low: 29,
                mid: 44,
                high: null
            }
        default:
            return {
                low: 0,
                mid: null,
                high: null
            }
    }


}