import AppConfiguration from "./AppConfiguration";

const loadConfiguration = (): AppConfiguration => {
    if (process.env.NODE_ENV === 'production') {
        console.log("loading production configurations");
        return {
            env: process.env.NODE_ENV,
            appRoot: 'https://breadthofthewild.com/',
            apiRoot: 'https://api.breadthofthewild.com'
        }
    }
    else if (process.env.NODE_ENV === 'development') {
        console.log("loading development configurations");
        return {
            env: process.env.NODE_ENV,
            appRoot: 'http://localhost:3000',
            // apiRoot: 'http://localhost:8080'
            apiRoot: 'https://api.breadthofthewild.com'
        }
    }
    else if (process.env.NODE_ENV === 'test') {
        console.log("loading test configurations");
        return {
            env: process.env.NODE_ENV,
            appRoot: 'http://localhost:3000',
            apiRoot: 'http://localhost:8080'
            // apiRoot: 'https://api.breadthofthewild.com'
        }
    }
    else {
        throw Error("Unknown environment: " + process.env.NODE_ENV);
    }
};

export const CONFIG: AppConfiguration = loadConfiguration();