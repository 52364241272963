import React, {useContext, useEffect, useMemo} from 'react';
import {FoodMaterial} from "../models/FoodMaterial";
import Loading from "./Loading";
import BotwListItem from './BotwListItem';
import {createStyles, darken, Grid, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import EffectIndicator from "./EffectIndicator";
import {FoodContext} from "../context/FoodContext";
import {
    calculateCookedHeartsFromMaterialsAndEffect, calculateCookedMaterialValue,
    calculateCookingEffectFromMaterials,
    calculateDisplayEffectDiff,
    calculateEffectTimeFromMaterials,
    calculatePointsFromMaterialsAndEffect, calculateThresholdFromEffectandPoints, calculateTotalMealValue,
    elixerEffects,
    foodEffects, getPointRangeFromEffect, maxEffectPoints
} from "../lib/CookingCalcs";
import {Effect} from "../models/Effect";
import BotwTileItem from "./BotwTileItem";
import {FoodPrep} from "../models/FoodPrep";
import {CasinoTwoTone, HourglassEmpty, TrendingUpTwoTone, Whatshot} from "@material-ui/icons";
import {PointRange} from "../models/PointRange";
import rupee from "../assets/rupee.png";
import theme from "../styles/theme";
import Rupees from './Rupees';

const useStyles = makeStyles( (theme: Theme) => (
    createStyles({
        imgContainer: {
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
        },
        remove: {
            display: "flex",
            flexFlow: "center center",
        },
        typographyActive: {
            [theme.breakpoints.down('xs')]: {
                fontSize: "1.25rem"
            }
        },
        typographyInactive: {
            color: darken(theme.palette.common.white, .35)
        },
        grow: {
            flexGrow: 1
        }
    })
));

interface Props {
    foodMaterial: FoodMaterial | null,
    index?: number
}

const FoodMaterialTile = ((props: Props) => {
    const classes = useStyles();
    const { foodMaterial, index } = props;
    const { addMaterialToFood, foodPrepState, getFoodMaterialsByEffect } = useContext(FoodContext);

    const currentFood: FoodPrep = foodPrepState?.data!;
    const combinedMaterials: FoodMaterial[] =
        currentFood.foodMaterials.length <= 5 ?
            currentFood.foodMaterials.concat(foodMaterial!) :
            currentFood.foodMaterials
    ;

    const combinedEffect: Effect =  calculateCookingEffectFromMaterials(combinedMaterials);
    const combinedPoints: number = calculatePointsFromMaterialsAndEffect(combinedMaterials, combinedEffect);
    const combinedTime: number = calculateEffectTimeFromMaterials(combinedMaterials, combinedEffect);
    const combinedHearts: number = calculateCookedHeartsFromMaterialsAndEffect(combinedMaterials, combinedEffect);
    const combinedPointrange: PointRange = getPointRangeFromEffect(combinedEffect);
    const combinedRupees: number = calculateTotalMealValue(combinedMaterials);

    const combinedFood: FoodPrep = {
        foodMaterials: combinedMaterials,
        foodEffect: combinedEffect,
        effectPoints: combinedPoints,
        effectTime: combinedTime,
        cookedHearts: combinedHearts,
        effectPointRange: combinedPointrange,
        rupees: combinedRupees
    }

    let displayEffectDiff = calculateDisplayEffectDiff(currentFood.foodEffect, combinedEffect, foodMaterial?.effectName!);
    let displayTime: number = combinedTime - currentFood!.effectTime;
    let displayHearts: number = combinedHearts -  currentFood.cookedHearts;

    const displayPoints = (isEffectCanceling: boolean, currentFood: FoodPrep, combinedFood: FoodPrep) => {
        if (isEffectCanceling) {
            return currentFood.effectPoints * -1;
        }
        if (combinedEffect === Effect.DUBIOUS) return 1;
        const foodPoints = foodMaterial?.effectPoints!;
        let remainingPoints =
            calculateThresholdFromEffectandPoints(combinedFood?.foodEffect, combinedFood.effectPoints) - calculateThresholdFromEffectandPoints(currentFood?.foodEffect, currentFood.effectPoints);

        remainingPoints = remainingPoints >= 0 ? remainingPoints : 0;

        const displayPoints = foodPoints > remainingPoints ? remainingPoints : foodPoints;


        return foodMaterial?.effectName! === currentFood.foodEffect! ? displayPoints : foodPoints;
    }

    //effect diff
    const effects = foodEffects.concat(elixerEffects);

    const elixerBadges = () => {
        const badges = [];
        for (let i = 0; i < foodMaterial!.effectPoints; i++) {
            badges.push(<Grid item key={i}><Whatshot color="disabled" /></Grid>);
        }
        return badges;
    }

    if (foodMaterial === null || foodPrepState.data!.foodMaterials === null) return <Loading message={"foodMaterial"} />

    if (foodPrepState.data === null) {
        return <Loading message={"foodPrepState"} />;
    }

    return(
        <BotwListItem bgEffect={foodMaterial.effectName}>
            <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                    <Typography variant="overline" noWrap>{`${foodMaterial.material.displayName} `}
                    </Typography>
                </Grid>
                <Grid item spacing={1} container xs={8} alignItems="center" justify="flex-end">
                    {foodMaterial.crit > 0 && (
                        <Grid item xs={4}>
                            <Typography variant="overline">{` crit. ${foodMaterial.crit} `}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={4} justify="center" direction="row" container>
                        {foodMaterial.effectName === Effect.TIME && <HourglassEmpty color={displayTime! >  30 ? "secondary" : "disabled"} fontSize="small" />}
                        {foodMaterial.effectName === Effect.ELIXER && elixerBadges()}
                    </Grid>
                    <Grid item xs={4} container alignItems="center" direction="row">
                        +<Rupees value={calculateCookedMaterialValue(combinedMaterials, foodMaterial)}/>
                    </Grid>
                </Grid>

                <Grid item xs={12} container alignItems="center">
                    <Grid item>
                        <BotwTileItem>
                            <img alt={foodMaterial.material.displayName}
                                 src={`/images/materials/${foodMaterial.material.imgUrl}`}
                                 height="50" width="50"
                                 onClick={(e) => {
                                     e.preventDefault()
                                     addMaterialToFood(foodMaterial);
                                 }}
                            />
                        </BotwTileItem>
                    </Grid>
                    <Grid item className={classes.grow}>
                        <EffectIndicator
                            effect={displayEffectDiff.effect}
                            points={displayPoints(displayEffectDiff!.isEffectCanceling, currentFood!, combinedFood)}
                            hearts={displayEffectDiff!.isEffectCanceling ? displayHearts! : 0}
                            time={displayTime!}
                            pointRange={displayEffectDiff!.isEffectCanceling ? combinedPointrange : 0}
                        />
                    </Grid>
                </Grid>


                {/*<Grid item>*/}

                {/*    <Typography variant="body1">{calculateCookingEffectFromMaterials(foodPrepState!.data!.foodMaterials!.concat(foodMaterial!))}</Typography>*/}
                {/*</Grid>*/}
            </Grid>
        </BotwListItem>
    )
});

export { FoodMaterialTile as default }