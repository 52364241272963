import React, {useContext, useEffect} from "react";
import {
    createStyles,
    Grid,
    Paper,
    Theme,
    Container,
    Typography,
    Button,
    fade,
    darken,
    Card,
    List, ListItem, ListItemText
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import {useAuth0} from "../react-auth0-spa";
import bg from "../assets/shrine-bg.jpg";
import {MeContext} from "../context/MeContext";
import {NavLink} from "react-router-dom";
import ReactGA, {OutboundLink} from "react-ga";
import Loading from "../components/Loading";
import theme from "../styles/theme";
import {CONFIG} from "../config";

const useStyles = makeStyles( (theme: Theme) => (
    createStyles({
        root: {
          maxHeight: '100%',
          overFlowY: 'scroll',
            height: '100%'
        },
        hylia: {
            fontFamily: "Hylia",
            paddingBottom: theme.spacing(3),
        },
        homeContainer: {
            overflowY: "scroll",
            padding: theme.spacing(2),
            background: `url(${bg})`,
            backgroundSize: "cover",
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(4),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(8),
                background: `url(${bg})`,
                backgroundSize: "cover"
            }
        },
        paper: {
            padding: theme.spacing(4),
            background: fade(theme.palette.common.black, .85),
            color: theme.palette.primary.light
        },
        card: {
            display: "flex",
            flexDirection: "column",
            background: "transparent",
            [theme.breakpoints.up('sm')]: {
                flexDirection: "row"
            }
        },
        image: {
            maxWidth: "100%",
            height: "auto"
        },
        armorAndMaterials: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            [theme.breakpoints.up('md')]: {
                flexDirection: "row",
                flexWrap: "wrap"
            }
        },
        loginButton: {
            margin: theme.spacing(2),
            marginLeft: 0
        }
    })
));

const Home = () => {
    const classes = useStyles();
    const {loginWithPopup} = useAuth0();
    const {meState, tokenState} = useContext(MeContext);

    useEffect( () => {
        // console.log(CONFIG.env);
        if (CONFIG.env === "production") {
            ReactGA.set({ screenSize: `${window.screen.width}x${window.screen.height}`})
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []);

    return(
        <Container className={classes.homeContainer} maxWidth={false}>
            <Grid container spacing={4} direction="column" justify="center" alignItems="center">
                <Grid item xs={12} xl={6}>
                    <Paper className={classes.paper}>
                        {/*<Typography className={classes.hylia} variant="body2" noWrap={true}>*/}
                        {/*    Temporarily down for maintenance*/}
                        {/*</Typography>*/}

                        {/*<Typography className={classes.hylia} variant="body2" noWrap={true}>*/}
                        {/*    AnonJwt: {tokenState.data && tokenState.data}*/}
                        {/*</Typography>*/}
                        <Typography className={classes.hylia} variant="body1">
                            Welcome to my Breath of the Wild Logbook project!  Use this tool to track your progress in Breath of the wild, including Armor Tracking, Armor Upgrade Calculator, and Shrine Tracking!
                        </Typography>
                        <Typography className={classes.hylia} variant="body1">
                            Work (forever) in Progress : Breath of the Wild Cooking Effects Calculator
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <Paper className={classes.paper}>
                        {/*<Typography color="primary" className={classes.hylia} variant="h3">*/}
                        {/*    Current Features:*/}
                        {/*    <span>*/}
                        {/*        <Typography variant="overline">v0.20.5.16</Typography>*/}
                        {/*    </span>*/}
                        {/*</Typography>*/}
                        {!meState.loading && meState.data != null && (
                            <>
                                <Typography variant="h6">ChangeLog</Typography>

                                <List style={{ marginBottom: theme.spacing(2), maxHeight: "45vh", overflowY: "scroll"}}>
                                    <ListItem>
                                        <ListItemText primary="17 May 2023 - Updated UI for cooking.  Still WIP but way better on mobile. " />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="21 April 2023 - Fixed the cooking calcs" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="9 Mar 2023 - If the effect of what you are cooking changes, the ingredients list scrolls to the top." secondary="I might have screwed up cooking ingredients filtering with that last update :(  Investigating..." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="21 Feb 2023 - Added sorting to shrines. Redeployed api " secondary="A lot left to do too..." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="27 Dec 2020 - Cooking Calcs now include Rupees." secondary="All values shown are for materials that have been cooked." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="18 Nov 2020 - Various tweaks." secondary="Adding meals and tracking materials for tracked meals is next feature to be developed." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="1 Nov 2020 - Cooking Calc is looking much better." secondary="Adding meals and tracking materials for tracked meals is next feature to be developed." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="11 Oct 2020 - Cooking is less terrible but still WIP" secondary="Change from EC2 -> S3 + AWS tweaks = no more $80/month hosting fees!" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="2 Oct 2020 - Swapped out Auth0 for AnonAuth.  No more saving across devices.  For now." secondary="No more barrier to entry.  Test the app out!  You can't save your data anymore though.  Will be adding this feature (and your previously saved data!) back in shortly!" />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="16 May 2020 - Updated Armor and Shrine Search" secondary='Considerably fewer api calls = faster ui' />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="12 May 2020 - Updated Armor List" secondary='"removing" armor now sets to inactive.  Slight ui improvement.' />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary="11 May 2020 - Updated Shrines Database" secondary="added dlc shrines." />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary=" - Updated UserShrines Database" secondary="fixed error where all user-shrines saving to userId = 0 :(" />
                                    </ListItem>
                                </List>
                                <Typography className={classes.hylia} variant="body2">
                                    I hope you find this Breath of the Wild companion useful!
                                </Typography>
                                <Typography className={classes.hylia} variant="body2">
                                <OutboundLink eventLabel={"Leave feedback about this breath of the wild guide"} to="https://forms.gle/dUqmPLbTudaHb6Af6" >
                                    Please Provide Your Valuable Feedback!
                                </OutboundLink>
                                </Typography>

                            </>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <Paper className={classes.paper}>
                        <Typography variant="h5" className={classes.hylia}>Breath of the Wild Armor Upgrade Calculator and Armor Tracking</Typography>
                        <Grid container spacing={4} className={classes.armorAndMaterials}>
                            <Grid item>
                                <Card className={classes.card}>
                                    <img alt="screenshot of breath of the wild Armor Tracking List" className={classes.image} src="/images/armor.png" />
                                </Card>
                            </Grid>
                            <Grid item>
                                <Card className={classes.card}>
                                    <img alt="screenshot of breath of the wild Armor Upgrade Calculated Upgrades" className={classes.image} src="/images/materials.png" />
                                </Card>
                            </Grid>
                            <Grid item xs={12} container justify="flex-start">
                                <NavLink to="/materials">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                    >
                                        {meState.loading && ("Loading...")}
                                        {!meState.loading && ("Track my Armor!")}
                                    </Button>
                                </NavLink>
                            </Grid>
                        </Grid>

                    </Paper>
                </Grid>
                <Grid item xs={12} xl={6} container justify="center" alignItems="center">
                    <Paper className={classes.paper}>
                        <Typography variant="h5" className={classes.hylia}>Breath of the Wild Shrine Tracking</Typography>
                        <Grid container spacing={4} justify="center" alignItems="center" direction="column">
                            <Grid item xs={12}>
                                <img alt="screenshot of Breath of the Wild Shrine Tracking" className={classes.image} src="/images/shrineChecklist.png" />
                            </Grid>
                            <Grid item xs={12} container justify="flex-start">
                                <NavLink to="/shrines">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                    >
                                        {meState.loading && ("Loading...")}
                                        {!meState.loading && ("Track my Shrines!")}
                                    </Button>
                                </NavLink>
                            </Grid>
                        </Grid>
                    </Paper>

                </Grid>
                <Grid item xs={12} xl={6} container justify="center" alignItems="center">
                    <Paper className={classes.paper}>
                        <Typography variant="h5" className={classes.hylia}>Breath of the Wild Cooking Calculator</Typography>
                        <Grid container spacing={4} justify="center" alignItems="center" direction="column">
                            <Grid item xs={12}>
                                <img alt="screenshot of Breath of the Wild Cooking Calculator" className={classes.image} src="/images/cooking.png" />
                            </Grid>
                            <Grid item xs={12} container justify="flex-start">
                                <NavLink to="/cooking">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                    >
                                        {meState.loading && ("Loading...")}
                                        {!meState.loading && ("Calculate my Food Effects!")}
                                    </Button>
                                </NavLink>
                            </Grid>
                        </Grid>
                    </Paper>

                </Grid>
            </Grid>

        </Container>
    )
};

export { Home as default };
