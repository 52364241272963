import React, {useContext, useEffect, useMemo, useState} from "react";
import { NavLink } from "react-router-dom";
import {
    createStyles,
    fade,
    Grid,
    IconButton,
    InputBase,
    SvgIcon,
    Theme,
    Tooltip,
    useMediaQuery,
    WithStyles
} from "@material-ui/core";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import CategoryIcon from "@material-ui/icons/Category";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {MeContext} from "../context/MeContext";
import svgMaterials from "../assets/materials.svg"
import Loading from "./Loading";
import classNames from "classnames";
import theme from "../styles/theme";
import {HomeSharp} from "@material-ui/icons";

// @ts-ignore
const styles = (theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    logo: {
        fontFamily: "Hylia",
        paddingTop: theme.spacing(2),
        textDecoration: "none !important",
        [theme.breakpoints.down('xs')]: {
            fontSize: 0,
            height: 0,
            display: "sr-only"
        },

        color: theme.palette.primary.light
    },
    menuButton: {
        marginRight: theme.spacing(4),
        [theme.breakpoints.up('sm')]: {
            // display: "block"
        }
    },
    autocompleteContainer: {
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    navRow: {
        justifyContent: "center"
    },
    appBar: {
        backgroundColor: theme.palette.secondary.dark,
        "& a": {
            textDecoration: "none !important"
        },
        border: `5px dashed ${theme.palette.primary.light}`
    },
    bottomNavRow: {
        background: theme.palette.primary.main
    },
    navButton: {
        fontFamily: "hylia",
        textDecoration: "none !important",
        color: theme.palette.primary.light
    },
    navButtonDark: {
        fontFamily: "hylia",
        textDecoration: "none !important",
        color: theme.palette.primary.light
    },

    authLink: {
        justifySelf: "flex-end"
    },
    logoutButton: {
        flexGrow: 0
    },
    hiddenSmUp: {
        fontFamily: "hylia",
        textDecoration: "none !important",
        [theme.breakpoints.up('sm')]: {
            display: "none"
        }
    },
    desktopNav: {
        overflowX: "scroll",
        flexWrap: "nowrap",
        // display: "none",
        [theme.breakpoints.up('sm')]: {
            overflowX: "unset",
        }

    },
    '.sr-only': {
        position: "absolute",
        clip: "rect(1px, 1px, 1px, 1px)",
        padding: "0 !important",
        border: "0 !important",
        height: "1px !important",
        width: "1px !important",
        overflow: "hidden",
    },
    mobileNav: {
        // [theme.breakpoints.up('sm')]: {
            display: "none"
        // }
    },
    bigFatTooltip: {
        fontSize: ".9rem"
    },
    selected: {
        "& .MuiButton-root": {
            color: theme.palette.error.light
        },
        "& .MuiTypography-root": {
            color: theme.palette.error.light
        }
    },
    selectedWip: {
        "& .MuiButton-root": {
            background: theme.palette.warning.dark,
            color: theme.palette.warning.contrastText
        }
    },
    flashWhenReady: {
        animation: "glow 1s infinite"
    },
});

const BigFatTooltip = withStyles( (theme: Theme) => ({
    tooltip: {
        fontSize: "1rem",
        backgroundColor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
        padding: theme.spacing(2),
        fontFamily: "Hylia"
    }
}))(Tooltip);

interface Props extends WithStyles<typeof styles> {

}

const images = [
    { url: "../assets/tl-2.png", title: "breadthLogo" },

]
const NavBar = withStyles(styles)(({classes}: Props) => {
    const {userArmorInventoryState, userArmorMaterialsState} = useContext(MeContext);
    const { meState } = useContext(MeContext);
    const [number, setNumber] = useState<number>(0);
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));


    const materialsLength = useMemo( () => {
        if (userArmorMaterialsState.data !== null) {
            return userArmorMaterialsState.data.length;
        }
        return 0;
    }, [userArmorMaterialsState]);

    useEffect( () => {
        if (meState.data !== null) {
            setNumber(meState.data.id);
        }

    }, [meState]);

    return (
        <AppBar position="sticky" className={classes.appBar}>
            <Toolbar>
                <Grid container justify="center">
                    <Grid item xs={12} container justify="center">
                        <NavLink exact to="/" style={{ textDecoration: "none" }} aria-label="Breadth of the wild Home" activeClassName={classes.selected}>
                            <Typography variant="h4" className={classes.logo}>Breadth of the Wild</Typography>
                        </NavLink>
                    </Grid>
                    <Grid item xs={12} container justify="center" alignItems="center" className={classes.desktopNav}>
                        <NavLink exact to="/" aria-label="Breadth of the wild Home" activeClassName={classes.selected} className={classes.hiddenSmUp}>
                            <Button className={classes.navButton}>
                                <HomeSharp />
                            </Button>
                        </NavLink>
                        <NavLink to="/armor" exact activeClassName={classes.selected} aria-label="Armor Tracking">
                            <Button className={classes.navButton}>
                                Armor
                            </Button>
                        </NavLink>
                        <NavLink to="/koroks" exact activeClassName={classes.selected} aria-label="Korok Checklist">
                            <Button className={classes.navButton}>
                                Koroks
                            </Button>
                        </NavLink>
                        <NavLink to="/shrines" activeClassName={classes.selected} aria-label="Shrine Tracking">
                            <Button className={classes.navButton}>Shrines</Button>
                        </NavLink>
                        <NavLink to="/cooking" activeClassName={classes.selected} aria-label="Cooking Calculator">
                            <Button className={classes.navButton}>Cooking</Button>
                        </NavLink>
                    </Grid>
                </Grid>
                {/*<NavLink to="/profile" style={{ textDecoration: 'none' }}>*/}
                    <Typography variant="caption" style={{ color: theme.palette.primary.dark, position: 'absolute', top: theme.spacing(0), right: theme.spacing(1) }}>
                        Hylian #{number}
                    </Typography>
                {/*</NavLink>*/}
            </Toolbar>
        </AppBar>
    );
});

export default NavBar;
