import React, {useContext, useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {Container, createStyles, fade, Grid, Theme, Typography, Select, MenuItem, FormHelperText} from "@material-ui/core";
import AllShrinesList from "../components/AllShrinesList";
import bg from "../assets/shrine-bg.jpg";
import {makeStyles} from "@material-ui/core/styles";
import BotwShrinesAutocomplete from "../components/BotwShrinesAutocomplete";
import {MeContext} from "../context/MeContext";
import {ShrineContext} from "../context/ShrineContext";
import theme from "../styles/theme";
import {Shrine} from "../models/Shrine";
import {Region} from "../models/Region";
import {ShrineStatus} from "../models/ShrineStatus";

const useStyles = makeStyles( (theme: Theme) => (
    createStyles({
        root: {
            background: `url(${bg})`,
            backgroundSize: "cover",
            height: "100%",
            maxHeight: '100%'
        },
        paperShrinesContainer: {
            maxHeight: "65vh",
            overflowY: "scroll",
            padding: theme.spacing(2),
            background: fade(theme.palette.secondary.contrastText, .5)
        },
        shrineGrid: {
            [theme.breakpoints.up('md')]: {
                maxWidth: theme.breakpoints.values.sm,
            }
        },
        materialsGrid: {


            paddingBottom: theme.spacing(2),
            [theme.breakpoints.up('md')]: {}
        },
        materialsHeader: {
            margin: theme.spacing(2),
            fontFamily: "Hylia",
            [theme.breakpoints.down('xs')]: {
                display: "none"
            }
        },
        '@global': {
            '*::-webkit-scrollbar': {
                width: '0.4em'
            },
            '*::-webkit-scrollbar-track': {
                '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '*::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(0,0,0,.1)',
                outline: '1px solid slategrey'
            }
        },
        shrineCountContainer: {
            background: fade(theme.palette.secondary.contrastText, .5),
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            display: "flex",
            justifyContent : "space-between"

        },
        filterBox : {
            color: theme.palette.primary.contrastText,
            "@global label" : {
                color:theme.palette.primary.contrastText,
                marginRight : "10px"
            },
        },
        select : { 
            "@global svg" : {
                color:theme.palette.primary.contrastText
            },
        },
        "@global .MuiInput-underline:before" : { 
            color:theme.palette.common.white,
            "border-bottom-color" : theme.palette.common.white
        },
    })
));

const FilterBox = ({
    sortByState,
    onSortByChange,
  }: {
    sortByState: string;
    onSortByChange: (value: string) => void;
  }) => {
    const classes = useStyles();
    return (
      <div className={classes.filterBox}>
        <label>Sort By</label>
        <Select
          label="Sort By" className={classes.select}
          value={sortByState}
          onChange={(event) => onSortByChange(event.target.value as string)}
        >
          <MenuItem value="region">Region</MenuItem>
          <MenuItem value="displayName" >Name</MenuItem>
        </Select>
      </div>
    );
  };

const Shrines = () => {
    const classes = useStyles();
    const {userShrinesState} = useContext(MeContext);
    const {shrineSortByState, setShrineSortByState} = useContext(ShrineContext);
    const [userShrinesRemaining, setUserShrinesRemaining] = useState<number>(120)

    useEffect( () => {
        if (userShrinesState.data !== null) {
            const beatenShrines = userShrinesState.data.filter( userShrine => {
                return userShrine.status === ShrineStatus.BEATEN && userShrine.shrine.region !== Region.CHAMPIONS_BALLAD
            }).length;

            setUserShrinesRemaining(
                120 - beatenShrines
            )
        }
    }, [userShrinesState.data])


    function shrineIsntDlc(shrine: Shrine): boolean {
        return shrine.region !== Region.CHAMPIONS_BALLAD;
    }

    return (
        <Container className={classes.root} maxWidth={false}>
            <Grid container justify="flex-start" style={{ paddingBottom: theme.spacing(4)}}>
                <Grid item  xs={12} sm={7} md={6} className={classes.shrineGrid}>
                    <BotwShrinesAutocomplete />
                    <Paper elevation={3} className={classes.shrineCountContainer}>
                        <Typography variant="subtitle1" color="secondary">Shrines Remaining: {userShrinesRemaining}/120</Typography>
                        <FilterBox sortByState={shrineSortByState} onSortByChange={setShrineSortByState} />
                    </Paper>
                    <Paper className={classes.paperShrinesContainer} elevation={4}>
                        <AllShrinesList />
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    )
};

export default Shrines;
