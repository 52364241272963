import {FoodPrep} from "../models/FoodPrep";
import {Box, Grid, Typography} from "@material-ui/core";
import rupee from "../assets/rupee.png";
import theme from "../styles/theme";
import React, {useContext} from "react";
import {FoodContext} from "../context/FoodContext";
import {calculateCookedMaterialValue} from "../lib/CookingCalcs";

interface RupeesProps {
    value: number
}

const Rupees = (props: RupeesProps) => {
    const {foodPrepState} = useContext(FoodContext);
    const rupees = props.value;

    if (foodPrepState === null) return <></>;

    return (
        <Grid item>
            <Box position="relative">
                <img height="25" width="25" src={rupee} alt={`these ingredients are worth ${rupees} rupees`} />
                <Typography variant="button" style={{
                    color:theme.palette.common.white,
                    position: 'absolute',
                    left: 15,
                    bottom:-1
                }}>{rupees}</Typography>
            </Box>
        </Grid>
    )
}

export { Rupees as default }