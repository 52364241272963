import React, {useContext, useEffect, useMemo} from "react";
import Paper from "@material-ui/core/Paper";
import {
    Box, Button,
    Container,
    createStyles,
    fade,
    Grid, Snackbar,
    Theme,
    Typography,
    useMediaQuery,
    withStyles,
    WithStyles
} from "@material-ui/core";
import AllArmorList from "../components/AllArmorList";
import UserArmorMaterialsList from "../components/UserArmorMaterialsList";
import BotwArmorAutocomplete from "../components/BotwArmorAutocomplete";
import bg from "../assets/neat.jpg";
import theme from "../styles/theme";
import {MeContext} from "../context/MeContext";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@material-ui/icons";
import {useHistory} from "react-router";
import {ArmorContext} from "../context/ArmorContext";


const styles = (theme: Theme) => createStyles({
    root: {
        background: `url(${bg})`,
        backgroundSize: "cover",
        justifyContent: "center",
        height: "100%",
        maxHeight: "100%",
        overflowY: 'scroll',
    },
    paperArmorContainer: {
        maxHeight: "75vh",
        overflowY: "scroll",
        padding: theme.spacing(2),
        background: fade(theme.palette.secondary.main, .5)
    },
    paperMaterialsContainer: {
        maxHeight: "75vh",
        overflowY: "scroll",
        padding: theme.spacing(2),
        background: fade(theme.palette.secondary.main, .5),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(-2),
            marginRight: theme.spacing(-2),
            maxHeight: "68vh"
        }
    },
    armorGrid: {
        [theme.breakpoints.up('md')]: {
            maxWidth: theme.breakpoints.values.sm,
        }
    },
    materialsGrid: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.up('md')]: {

        }
    },
    materialsHeader: {
        margin: theme.spacing(2),
        fontFamily: "Hylia",
        color: theme.palette.primary.main
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    // armor: UserArmorDisplay
}

const Upgrades = withStyles(styles)(({classes}: Props) => {
    const {userArmorMaterialsState} = useContext(MeContext);
    const {userArmorInventoryState} = useContext(ArmorContext);
    const smUp = useMediaQuery(theme.breakpoints.up("sm"));
    const history = useHistory();

    const randomArmorImg = useMemo(() => {
        if (userArmorInventoryState.data) {
            const randomIndex = Math.floor(Math.random() * userArmorInventoryState.data.filter( data => data.active).length);

            return `/images/armor/${userArmorInventoryState.data.filter(data => data.active)[randomIndex].armor.imgUrl}`;
        }

    }, [userArmorInventoryState])

    return (
        <Container className={classes.root} maxWidth={false}>
            <Grid container spacing={4} justify="center">
                <Grid item md={6} lg={8} xl={9}  className={classes.materialsGrid}>
                    <Box width="100%" py={2} style={{ backgroundColor: fade(theme.palette.common.black, .8)}}>
                        <Typography variant="h5" align="center" color='primary'>
                            Armor Upgrades
                        </Typography>
                        <Typography variant="body2" align="center" color="primary">
                            (Wooing them Fairies like...)
                        </Typography>
                    </Box>
                    <Paper className={classes.paperMaterialsContainer} elevation={4}>
                        <UserArmorMaterialsList />
                    </Paper>
                </Grid>
                {smUp &&
                    <Grid item  xs={12} md={6} lg={4} xl={3} className={classes.armorGrid}>
                        <BotwArmorAutocomplete />
                        <Paper className={classes.paperArmorContainer} elevation={4}>
                            <AllArmorList />
                        </Paper>
                    </Grid>
                    ||
                    <Snackbar open={userArmorMaterialsState?.data?.length!! > 0 || false} style={{ bottom: theme.spacing(5)}}>
                        <Button variant="contained"
                                endIcon={<img height={35} width="auto" src={randomArmorImg} />}
                                startIcon={<KeyboardArrowLeft fontSize="large" />}
                                onClick={() => history.push("/armor")}
                        >
                            Armor Checklist
                        </Button>
                    </Snackbar>}
            </Grid>
        </Container>
    )
});

export default Upgrades;
