import {Card, createStyles, Grid, Paper, Theme} from "@material-ui/core";
import React from "react";
import {makeStyles, fade} from "@material-ui/core/styles";
import theme from "../styles/theme";

const useStyles = makeStyles( (theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(1),
            borderColor: theme.palette.grey["500"],
            borderStyle: "solid",
            borderWidth: "2px",
            backgroundColor: fade(theme.palette.grey["700"], .85),
            color: theme.palette.grey["100"],
            width: "100%",
            height: "100%",
            cursor: "pointer"
        },
        emptyPaper: {
            width: "0%",
            height: "0%",
        },
        paperBacking: {
            padding: "2px",
            backgroundColor: fade(theme.palette.grey["600"], .85),
            margin: "4px",
            width: "74px",
            height: "74px"
        },
        emptyPaperBacking: {
            padding: "2px",
            backgroundColor: fade(theme.palette.grey["700"], .85),
            margin: "4px",
            width: "74px",
            height: "74px"
        },
        fullHeight: {
            height: "100%"
        }
    })
);

interface Props {
    children: React.ReactNode,
    empty?: boolean
}

const BotwTileItem = (props: Props) => {
    const classes = useStyles();

    return (
        <Card elevation={props.empty && 0 || 4}  className={ props.empty && classes.emptyPaperBacking || classes.paperBacking}>
            <Card className={props.empty && classes.emptyPaper || classes.paper}>
                <Grid container alignItems="center" justify="center" className={classes.fullHeight}>
                    <Grid item>
                        {props.children}
                    </Grid>
                </Grid>
            </Card>
        </Card>
    )
}

export { BotwTileItem as default }